import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Contact/Contact.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Footer/Footer.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Intro/Intro.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/PreLoader/PreLoader.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ProjectSection/ProjectSection.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ScrollIndicator/ScrollIndicator.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Skills/Skills.tsx");
