'use client';
import { easeInOut, motion, useScroll, useTransform } from 'framer-motion';
import Image from 'next/image';
import { useRef } from 'react';
import HeroBackground from '../HeroBackground/HeroBackground';

const Intro = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const x = useTransform(scrollYProgress, [0, 1], ['100%', '-100%'], {
    ease: easeInOut,
  });
  const y = useTransform(scrollYProgress, [0, 1], ['0%', '-10%'], {
    ease: easeInOut,
  });

  const position = useTransform(
    scrollYProgress,
    [0, 1],
    ['sticky', 'absolute'],
    {
      ease: easeInOut,
    }
  );

  return (
    <section
      id='intro'
      className={`relative w-full h-[300vh] sm:h-[300vh] bg-transparent overflow-clip`}
      ref={targetRef}>
      <motion.div
        transition={{
          type: 'spring',
          velocity: 1.5,
          duration: 0.25
        }}
        style={{ x }}
        className='sticky top-[50vh] -translate-y-1/2 w-fit overflow-hidden -z-0 will-change-transform'>
        <div>
          <p className='text-6xl sm:text-8xl font-extrabold text-nowrap whitespace-nowrap'>
            {[
              'Quality solutions.',
              'Professional attitude.',
              'Keen learner.',
            ].map((feature) => (
                <motion.em
                key={feature}
                className='ml-2 uppercase will-change-[color,background]'
                initial={{
                  color: 'rgba(0, 0, 0, 0)',
                  background: 'rgba(0, 0, 0, 0)',
                  WebkitTextStrokeColor: '#87CEEB', // skyblue
                  WebkitTextStrokeWidth: '3px',
                }}
                whileInView={{
                  color: '#87CEEB', // skyblue
                  WebkitTextStrokeWidth: '0px',
                  background: '#3DCFCF',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'rgba(0, 0, 0, 0)',
                }}
                transition={{
                  ease: 'easeInOut',
                  duration: 0,
                }}
                viewport={{
                  amount: 0.4,
                }}>
                {feature}
                </motion.em>
            ))}
          </p>
        </div>
      </motion.div>

      <div
        className={
          'sticky top-[50vh] right-0 -translate-y-1/2 ml-auto bg-transparent -z-10 h-[300px] sm:h-[400px] lg:h-[600px]'
        }>
        <motion.div
          className='absolute right-8 top-0 w-[300px] sm:w-[400px] lg:w-[600px] h-full aspect-square will-change-[transform,opacity]'
          initial={{
            opacity: 0,
            x: 100,
          }}
          animate={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.2,
              delay: 0.2,
            },
          }}
          style={{
            y,
            position,
          }}>
          <Image
            className={'object-cover'}
            src={'/images/hero.webp'}
            alt={'The avatar of author'}
            fill={true}
            sizes={`
              (max-width: 768px) 300px,
              (max-width: 1024px) 400px,
              600px
            `}
            priority
          />
        </motion.div>
        <HeroBackground className='absolute top-1/2 left-0 -translate-y-1/2 w-full -z-20 h-[400px] md:h-auto' />
      </div>
    </section>
  );
};

export default Intro;
