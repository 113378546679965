import Image from 'next/image';

const HeroBackground = ({ className }: { className?: string }) => {
  return (
    <div className={'min-h-[400px] w-full ' + className}>
      <Image
        src={'/images/parallaxBg.webp'}
        alt={'Parallax Background'}
        fill={true}
        priority
        sizes={`
          (max-width: 1200px) 100vw,
          1200px
        `}
      />
    </div>
  );
};

export default HeroBackground;
