import PinnedProject from './PinnedProject';
import ProjectLineItem from './ProjectLineItem';
import Separator from './Separator';

type ICategorySection = {
  name: string;
  description: string;
  index: number;
  projects: Array<any>;
  currentProject: number;
  setCurrentProject(project: number): void
};

export default function CategorySection({
  name,
  description,
  index,
  projects,
  setCurrentProject,
  currentProject,
}: ICategorySection) {
  return (
    <div className='h-full px-8 flex flex-row my-auto'>
      {/* // Heading */}
      <div className='w-full md:basis-2/5'>
        <div className='relative w-fit min-w-[160px]'>
          <h2 className='inline-block text-4xl font-bold text-center text-primary-content absolute left-0 bottom-0'>
            {name}
          </h2>
          <span className='text-8xl md:text-9xl text-primary-content-100 opacity-30'>
            0{index + 1}
          </span>
        </div>
        <div className='h-full flex flex-row'>
          <Separator index={index} />
          <div className='flex flex-col md:flex-row gap-2 p-8'>
            <div className='flex flex-col gap-6'>
              <p className='text-lg text-secondary-content font-thin'>
                {description}
              </p>
              <h3 className='text-3xl uppercase text-primary-content'>
                {projects.length} projects
              </h3>

              <ul className=''>
                {projects.map(
                  (
                    {
                      name,
                      url,
                    }: {
                      name: string;
                      url: string;
                    },
                    index: number
                  ) => (
                    <ProjectLineItem
                      onMouseEnter={() => setCurrentProject(index)}
                      key={name}
                      index={index}
                      name={name}
                      url={url}
                    />
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <PinnedProject
        {...(projects[currentProject] || projects[0])}
        className='hidden md:basis-3/5 md:flex'
      />
    </div>
  );
}
